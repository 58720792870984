
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healthHduNHBj_45BEmHc_45lF8o9m3tPFV35quKuyQ1cReQbmdLEMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93RcvhEDh02yxsbHxUQiL9WOusoG7nl_45lZr5dX9e96nD8Meta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as _91IATA_93gYZGZnciFCljH2ht_NihVB1Qhzw07uo_45cWj5kU0ThGoMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue?macro=true";
import { default as _91IATA_93N06hO_ZSnLh1LggK1mO2y3HAECJKK5lY7xZQFWImknwMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue?macro=true";
import { default as _91IATA_93gND_Y9NzQk4ioCMOI7_45ds4wLUYSi9lMqys4g65516YYMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue?macro=true";
import { default as _91_46_46_46slug_93LX_QXuMay0gjqy_45el7_HRkgDmW7eWp1bd8AvEo5zUvoMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue?macro=true";
import { default as _91IATA_93ZeRCZBSFICCYNaRD3VJpmgF2wrb8sjPSkkIPjKZVjG4Meta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue?macro=true";
import { default as _91product_slug_93BCJTozQ_zbYJEjF48EbGQcB0_RBiSv7BzYX3uPRJxpcMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93JZIHNXdUzDjTjiqSW2zwUXblfqwSWRI9psxKo5sonQkMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93Djhu0J3LbyOgLm5_L2uvD2yhZLQ77pnfeGqW62I78vcMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue?macro=true";
import { default as _91product_slug_93y7l362zrS2iPx8_45wVReOlAVDbQa9daxpxw21naeHHgAMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue?macro=true";
import { default as component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRAMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRA } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "port-parking-IATA___en",
    path: "/port-parking/:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[IATA].vue")
  },
  {
    name: "airport-lounges-IATA___en",
    path: "/airport-lounges/:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[IATA].vue")
  },
  {
    name: "airport-parking-IATA___en",
    path: "/airport-parking/:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[IATA].vue")
  },
  {
    name: "subdomain-business-slug___en",
    path: "/subdomain/business/:slug(.*)*",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/subdomain/business/[...slug].vue")
  },
  {
    name: "airport-hotels-with-parking-IATA___en",
    path: "/airport-hotels-with-parking/:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[IATA].vue")
  },
  {
    name: "port-parking-location-product_slug___en",
    path: "/port-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/port-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-lounges-location-product_slug___en",
    path: "/airport-lounges/:location()/:product_slug()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-lounges/[location]/[product_slug].vue")
  },
  {
    name: "airport-parking-location-product_slug___en",
    path: "/airport-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-parking/[location]/[product_slug].vue")
  },
  {
    name: "airport-hotels-with-parking-location-product_slug___en",
    path: "/airport-hotels-with-parking/:location()/:product_slug()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/sps/pages/airport-hotels-with-parking/[location]/[product_slug].vue")
  },
  {
    name: component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRA
  }
]